.assessmentWrapper {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  width: 100%;
}
.assessment {
  font-size: 20px;
  color: #615d5d;
}
.assessmentContainer {
  margin: 10px auto;
  text-align: left;
  display: flex;
  flex-direction: row;
  padding: 24px 0px;
}
.assessmentBody {
  width: 632px;
  box-shadow: 0px 4px 6px #00000029;
  border-radius: 5px;
  background: white;
  padding: 15px;
  min-height: 60vh;
  margin-right: 10px;
}
.assessmentSide {
  width: 308px;
}
.hidden { display:none; }
@media only screen and (max-width: 768px) {
  .assessmentContainer {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .assessmentOverlay {
    display: none;
    position: absolute;
    z-index: 1;
    height: 100%;
    width: inherit;
    background: black;
    opacity: 0;
  }
  .activeOverlay {
    display: block;
    opacity: 0.5;
    -webkit-animation: 0.25s both display-none-transition;
    animation: 0.25s both display-none-transition;
  }
  @-webkit-keyframes display-none-transition {
    0% {
      opacity: 0;
    }
  }
  @keyframes display-none-transition {
    0% {
      opacity: 0;
    }
  }
  .assessmentBody {
    width: 90%;
    margin: 0px auto;
  }
  .assessmentSide {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
    z-index: 2;
  }
  .activeMenu {
    height: inherit;
  }
}

.submit {
  border: 1px solid #434343;
  font-size: 16px;
  min-width: 132px;
  /* height: 40px; */
  width: 100%;
  background: lightgray;
  color: black;
  border-radius: 4px;
  padding: 13px 0px;
  box-shadow: 0px 4px 6px #00000029;
  cursor: pointer;
  transition: background 0.25s ease-in-out;
}
.submit:hover {
  background: darkgray;
}
