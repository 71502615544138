.radioOptionWrapper,
.checkboxOptionWrapper {
  cursor: pointer;
  padding-top: 3px;
  /* height: 30px; */
  font-size: 10px;
  display: flex;
  align-items: center;
}
label {
  align-self: flex-start;
  padding-left: 0px;
}
.radioOptionWrapper:hover,
.checkboxOptionWrapper:hover {
  background: #f7f7f7;
}
