.emptyBar {
    display: block;
    height: 4px;
    background-color: #cccccc;
    margin: 3px 0px;
}

.fillerBar {
    display: block;
    height: 100%;
    background-color: #337DFF;
    transition: width 1s ease-in-out;
}