.sectionWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sectionUpperContainer {
  align-self: flex-start;
  flex: 2;
  width: 100%;
}
.sectionHeaderWrapper {
  padding: 10px 16px;
  color: #434343;
}
.sectionHeaderText {
  display: flex;
  justify-content: space-between;
}
.sectionHeaderDots {
  color: #337DFF;
  font-size: 30px;
  cursor: pointer;
}
.assessmentName {
  margin-left: 30;
  margin-right: auto;
  font-size: 32px;
  font-weight: bold;
}
.sectionName {
  margin-left: 30;
  margin-right: auto;
  font-size: 24px;
  font-weight: bold;
  padding: 5px 0px;
}
.sectionBody {
  padding: 0px 32px;
}
.sectionNav {
  flex: 1;
  align-self: flex-end;
  display: flex;
  bottom: 0px;
  width: 100%;
  height: 100px;
  justify-content: space-around;
  align-items: right;
}
.sectionNavBody {
  margin-left: auto; 
  margin-right: 30;
}
.navButton {
  background-color: #ffffff;
  color: #434343;
  min-width: 132px;
  height: 40px;
  font-size: 20px;
  font-family: 'Montserrat';
  font-weight: medium;
  border-radius: 10px;
  border: 1px solid #434343;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.navButton:hover {
  background: #4148e80d;
}

.navButton:active {
  background: #4148e826;
}
