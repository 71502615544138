.questionWrapper {
  font-size: 20px;
}
.questionText {
  font-size: 20px;
  padding: 16px 0px;
}
.questionCode {
  background-color: #fcfdfe;
  border: 1px solid #c7c7c7;
  font-size: 12px;
  padding: 16px 0px;
}
.questionAsset {
  padding: 8px 0px;
  height: auto;
  max-width: 100%;
}
