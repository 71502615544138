.results-wrapper {
  width: 100%;
}
.results-body {
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 0px;
}

.results-h1 {
  font-size: 32px;
}
.results-h2 {
  font-size: 22px;
  font-weight: normal;
  margin: 10px 0px;
}
.results-h3 {
  font-size: 18px;
}
.results-h4 {
  font-size: 16px;
  font-weight: bold;
}
.results-stairs {
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 200px;
  padding: 10px;
}
.results-step-wrapper {
  flex: 1;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.results-step {
  width: inherit;
  background-color: #4148e833;
  margin: 0px 5px;
  border-radius: 5px 5px 0px 0px;
}
.results-step-wrapper span {
  margin-bottom: 10px;
}
.active-step {
  background: linear-gradient(180deg, #4148e8 0%, #212474 100%);
}
.results-step-0 {
  height: 25%;
}
.results-step-1 {
  height: 50%;
}
.results-step-2 {
  height: 75%;
}
.results-step-3 {
  height: 100%;
}
.results-section li,
p {
  font-size: 16px;
}
.results-section {
  background: white;
  width: 956px;
  margin: 8px auto;
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0px 4px 6x #00000029;
  text-align: left;
  box-sizing: border-box;
}
.orange {
  background: #f15a20;
  color: white;
}
.results-doughnut {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: content-box;
}
.results-doughnut span {
  position: absolute;
}
.arrange-cols {
  display: flex;
  flex-direction: row;
  background: none;
  box-shadow: none;
  padding: 0px;
  margin: 8px auto;
  justify-content: space-between;
}
.arrange-cols > div {
  background: white;
  box-shadow: 0px 4px 6px #00000029;
  padding: 32px;
  border-radius: 5px;
}
.results-cols {
  display: flex;
  flex-direction: row;
  padding: 28px 10px;
}
.blue {
  background-color: #4148e80d;
}
.center-content {
  text-align: center;
}
.results-60 {
  width: 60%;
  margin: 0px 10px;
}
.results-50 {
  width: 50%;
  margin: 0px auto;
}
.arrange-cols > .results-50 {
  width: 405px;
  margin: 0px;
}
a {
  color: white;
  text-decoration: none;
  margin: 0px 5px;
}
.results-section a {
  color: white;
  text-decoration: underline;
  margin: 0px 5px;
}
hr {
  border: 1px solid #e2e2e2;
}
p {
  font-size: 16px;
}
.fullBar {
  width: 100%;
  background: #e2e2e2;
  height: 12px;
  display: block;
}
.fullBar > span {
  background: #4148e8;
  height: inherit;
  width: 90%;
  display: block;
}
.results-section-single {
  padding: 32px 80px;
}

.results-section-single-status {
  margin: 0px;
}

.outline-button {
  border: 1px solid white;
  background: none;
  box-shadow: none;
  border-radius: 4px;
  cursor: pointer;
  height: 80px;
  font-size: 13px;
  color: white;
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: medium;
  min-width: 276px;
  padding: 10px;
}
.results-section ul {
  text-indent: none;
  list-style-position: inside;
  padding: 0px;
  margin: 0px;
  list-style: none;
}
li {
  padding: 0px;
  margin-top: 15px;
}

.checkmark {
  display: inline-block;
  transform: rotate(45deg);
  height: 25px;
  width: 12px;
  margin-left: 60%; 
  border-bottom: 3px solid #78b13f;
  border-right: 3px solid #78b13f;
}

.cross {
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-left: 60%; 
}

.cross:after {
  content: "X";
  margin-left: 60%; 
  color: red;
}

.question-notanswered {
  color: black;
  border: 1px solid var(--green-60);
  color: var(--green-60);
}


.question-failed {
  color: red;
  border: 1px solid var(--green-60);
  color: var(--green-60);
}

.question-success {
  color: green;
  border: 1px solid var(--green-60);
  color: var(--green-60);
}

.questionText {
  font-size: 20px;
  padding: 16px 0px;
}
.questionCode {
  background-color: #fcfdfe;
  border: 1px solid #c7c7c7;
  font-size: 12px;
  padding: 16px 0px;
}
