.header {
    display: flex;
    width: 100%;
    color: #F15A20;
    height: 40px;
    font-size: 18px;
    background: white ;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 6px #00000029;
  }
.header img {
  width: 40px;
}  

@media only screen and (max-width: 768px) {
  .header {
    justify-content: space-around;
    align-items: center;
  }
}
